import React, { Suspense, lazy, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import UpdateDraftEditor from "./Editor/UpdateDraftEditor";
// import UpdateDraftEditor from './Editor/UpdateDraftEditor';
import { Editor, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import getNewAccessToken from "../../utils/GlobalRefreshToken/RefreshToken";
import DotsLoader from "../../common/DotsLoader";
import { trainingManualListingUrl } from "../../utils/urls";
import { validateForm } from "../../common/FormTemplates/callToActions";
import { sendLogToCloudWatch } from "../../common/utilsFunctions/sendLogToCloudWatch";

const Breadcrumbs = lazy(() => import("../../common/Breadcrumbs"));
const TextFiledComponent = lazy(() =>
  import("../../common/FormTemplates/TextFiledComponent")
);
const ToggleButtonComponent = lazy(() =>
  import("../../common/ToggleButtonComponent")
);
const ToasterComponet = lazy(() => import("../../common/ToasterComponet"));
const LoaderComponent = lazy(() => import("../../common/LoaderComponent"));

const EditTrainingManual = () => {
  const AxiosInstance = axios.create();
  const navigate = useNavigate();
  const hnts_user = JSON.parse(localStorage.getItem("hnts_user"));

  const location = useLocation();

  let key = JSON.parse(localStorage.getItem("hnts_access_token"));

  const breadcrumb = [
    {
      name: "Home",
      link: "/",
      isLink: true,
    },
    {
      name: "Help",
      link: "/help",
      isLink: true,
    },
    {
      name: "Edit Training Manual",
      link: "/help/update",
      isLink: false,
    },
  ];

  // toast message states
  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toastColor, setToastColor] = useState("");

  const [submitSpinner, setSubmitSpinner] = useState(false);

  const [visibilityState, setVisibilityState] = useState(
    location?.state?.visibility ? "yes" : "no"
  );

  const [editorContent, setEditorContent] = useState(() =>
    EditorState.createEmpty()
  );

  const [releaseNotes, setReleaseNotes] = useState({
    screen: "",
    notes: {
      blocks: [],
      entityMap: {},
    },
  });

  const [isReleaseNotes, setIsReleaseNotes] = useState({
    screen: false,
    notes: false,
  });

  const updateManualSuccessResponse = (response) => {
    setShowToaster(true);
    setToastColor("success");
    setToasterMessage(response?.data);

    setTimeout(() => {
      setSubmitSpinner(false);
      navigate(`/help`);
    }, 3000);
  };

  const updateManualFailureResponse = (error) => {
    console.log(error);
    setSubmitSpinner(false);
    setShowToaster(true);
    setToastColor("danger");
    setToasterMessage(error.response.data.Response.Message);
  };

  const submitManualNotes = () => {
    let errors = validateForm(releaseNotes);
    let isEmptyFormData = false;
    setIsReleaseNotes(errors);
    Object.keys(errors).map((value) => {
      if (errors[value] === true) {
        isEmptyFormData = true;
      }
      return "";
    });

    if (!isEmptyFormData) {
      let stringifyNotes = JSON.stringify(releaseNotes.notes);
      let payload = {
        screen_name: releaseNotes.screen,
        id: location?.state?.id,
        user_id: hnts_user?.pk,
        manual_text: stringifyNotes,
        action: "edit_manual",
        visibility: visibilityState === "yes" ? true : false,
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${key}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      AxiosInstance.post(
        trainingManualListingUrl,
        payload,
        { withCredentials: true },
        headers
      )
        .then((response) => {
          updateManualSuccessResponse(response);
          sendLogToCloudWatch(
            "Help Screen Update : update record Successfully"
          );
        })
        .catch(async (error) => {
          if (error?.response?.status === 401) {
            let requiredAccessToken = await getNewAccessToken();
            let headers = {
              headers: {
                Authorization: `Bearer ${requiredAccessToken}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            };

            requiredAccessToken !== undefined
              ? AxiosInstance.post(
                  trainingManualListingUrl,
                  payload,
                  { withCredentials: true },
                  headers
                )
                  .then((response) => {
                    updateManualSuccessResponse(response);
                    sendLogToCloudWatch(
                      "Help Screen Update : update record Successfully"
                    );
                  })
                  .catch((error) => {
                    if (error?.response?.status === 401) {
                      console.log("Access token not updated");
                    }
                    updateManualFailureResponse(error);
                  })
              : navigate("/logout");
          } else {
            updateManualFailureResponse(error);
          }
          sendLogToCloudWatch(
            `Help Screen Update : update record failed with status code ${error?.response?.status}`
          );
        });
    }
  };

  useEffect(() => {
    const contentcurrent = editorContent.getCurrentContent();
    const content = convertToRaw(contentcurrent);
    setReleaseNotes({ ...releaseNotes, notes: content });
  }, [editorContent]);

  useEffect(() => {
    let stateData = location.state;
    if (stateData === null) {
      navigate("/help");
    } else {
      setVisibilityState(stateData?.visibility ? "yes" : "no");

      let manualNotes =
        stateData?.walkthrough_notes !== "No Training Manual Available" &&
        stateData?.walkthrough_notes
          ? JSON.parse(stateData?.walkthrough_notes)
          : {
              blocks: [],
              entityMap: {},
            };

      setReleaseNotes({
        notes: manualNotes,
        screen: stateData?.screen ? stateData?.screen : "",
      });

      const contentState = convertFromRaw(manualNotes);
      const editReadState = EditorState.createWithContent(contentState);

      setEditorContent(editReadState);
    }
  }, [location]);

  return (
    <div className="page mx-3 training-manual">
      {/* toast message   Type */}
      {showToaster ? (
        <section className="fixed-top mt-5 pt-3">
          <Suspense fallback={<DotsLoader />}>
            <ToasterComponet
              setShowToaster={setShowToaster}
              color={toastColor}
              toasterMessage={toasterMessage}
            />
          </Suspense>
        </section>
      ) : null}

      <Container fluid>
        <Suspense fallback={<DotsLoader />}>
          <Breadcrumbs params={breadcrumb} />
        </Suspense>

        <section>
          <h1 className="tbl-page-title">Edit Training Manual</h1>

          <Form className="mt-4">
            <Row className="mb-3">
              <Col md={{ span: 6 }}>
                <Suspense fallback={""}>
                  <TextFiledComponent
                    labelName={"Screen Name"}
                    placeHolder={"enter screen name"}
                    name="screen"
                    value={releaseNotes.screen}
                    stateValue={releaseNotes}
                    setStateValue={setReleaseNotes}
                    validation={isReleaseNotes.screen}
                    isMandatory={true}
                  />
                </Suspense>
              </Col>

              <Col md={{ span: 6 }} className="text-start">
                <Form.Group className="mb-3">
                  <Form.Label bsPrefix="app-form-label fs-12 mb-2 form-label">
                    Visibility
                  </Form.Label>
                  <Suspense fallback={""}>
                    <ToggleButtonComponent
                      defaultButton={visibilityState}
                      setButtonValue={setVisibilityState}
                      labels={["yes", "no"]}
                    />
                  </Suspense>
                </Form.Group>
              </Col>
            </Row>
          </Form>

          <div className="mb-4">
            <UpdateDraftEditor
              editorContent={editorContent}
              setEditorContent={setEditorContent}
            />
          </div>

          <Button
            className="text-white fs-14 modal-submit-btn fw-bold d-block ms-auto me-0 px-5"
            style={{ height: "48px" }}
            onClick={submitManualNotes}
            disabled={submitSpinner ? true : false}
          >
            Save
            {submitSpinner ? (
              <Suspense fallback={<DotsLoader />}>
                <LoaderComponent />
              </Suspense>
            ) : null}
          </Button>
        </section>
      </Container>
    </div>
  );
};

export default React.memo(EditTrainingManual);
