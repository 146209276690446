import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";

import { ProtectedRoute, UnProtectedRoute } from "./utils/authentication";
import "./App.css";
import { getTheme } from "./getTheme";
import DotsLoader from "../src/common/DotsLoader";
import Logout from "./pages/Logout";
import { IsAuthorized } from "./common/utilsFunctions/IsAuthorized";
import EditTrainingManual from "./pages/Help/EditTrainingManual";
import { sendLogToCloudWatch } from "./common/utilsFunctions/sendLogToCloudWatch";

/* Importing components in lazy loading format */
const Help = lazy(() => import("./pages/Help"));
const Settings = lazy(() => import("./pages/Settings"));
const ReleaseHistory = lazy(() => import("./pages/Settings/ReleaseHistory"));
const NewRelease = lazy(() =>
  import("./pages/Settings/ReleaseHistory/NewRelease")
);
const UpdateRelease = lazy(() =>
  import("./pages/Settings/ReleaseHistory/UpdateRelease")
);
const DashBoard = lazy(() => import("./pages/DashBoard"));
const VolumeTracker = lazy(() => import("./pages/VolumeTracker"));

const LogIn = lazy(() => import("./pages/LogIn"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ProviderCredentialing = lazy(() =>
  import("./pages/ProviderCredentialing")
);
const NewRecord = lazy(() => import("./pages/ProviderCredentialing/NewRecord"));
const BillingDashBoard = lazy(() => import("./pages/Billing/BillingDashBoard"));
const BillingSecondScreen = lazy(() =>
  import("./pages/Billing/BillingSecondScreen")
);
const BillingThirdScreen = lazy(() =>
  import("./pages/Billing/BillingThirdScreen")
);
const Users = lazy(() => import("./pages/Settings/Users"));
const AgedAr = lazy(() => import("./pages/AgedAr"));
const ToBeBlled = lazy(() => import("./pages/ToBeBilled"));
const BillingVsRemittance = lazy(() => import("./pages/BillingvsRemittance"));
const BillingBatchReport = lazy(() => import("./pages/BillingBatchReport"));
const RemittancesInsurance = lazy(() => import("./pages/RemittancesInsurance"));
const DataPlayground = lazy(() => import("./pages/DataPlayground"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const SyncHistory = lazy(() => import("./pages/SyncHistory"));

function App() {
  let selectedTheme = getTheme("theme1");

  useEffect(() => {
    sendLogToCloudWatch("This is a test log message from athena dev");
    // sendLogToCloudWatch(`/home - logs from FE#`);
  }, []);

  return (
    <div className="App global-bg" style={selectedTheme}>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Suspense fallback={<DotsLoader height="vh-100" />}>
                  <DashBoard />
                </Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="*"
            element={
              <ProtectedRoute>
                <Suspense fallback={<DotsLoader height="vh-100" />}>
                  <PageNotFound />
                </Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="sync-history"
            element={
              <>
                {IsAuthorized("SyncHistory", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <SyncHistory />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/data-playground"
            element={
              <>
                {IsAuthorized("DataPlayGround", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <DataPlayground />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />

          <Route
            exact
            path="/users"
            element={
              <>
                {IsAuthorized("Users", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Users />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />

          <Route
            exact
            path="/settings"
            element={
              <>
                {IsAuthorized("Settings", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Settings />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/release-history"
            element={
              <>
                {IsAuthorized("ReleaseHistory", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <ReleaseHistory />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />

          <Route
            exact
            path="/help"
            element={
              <>
                <ProtectedRoute>
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Help />
                  </Suspense>
                </ProtectedRoute>
              </>
            }
          />

          <Route
            exact
            path="/help/update"
            element={
              <>
                <ProtectedRoute>
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <EditTrainingManual />
                  </Suspense>
                </ProtectedRoute>
              </>
            }
          />

          <Route
            exact
            path="/release-history/add"
            element={
              <>
                {IsAuthorized("ReleaseHistory", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <NewRelease />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/release-history/update"
            element={
              <>
                {IsAuthorized("ReleaseHistory", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <UpdateRelease />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/volume-tracker"
            element={
              <>
                {IsAuthorized("VolumeTracker", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <VolumeTracker />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />

          <Route
            exact
            path="/billing-dashboard"
            element={
              <>
                {IsAuthorized("Billing", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <BillingDashBoard />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />

          <Route
            exact
            path="/billing/appointments-listing"
            element={
              <>
                {IsAuthorized("Billing", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <BillingSecondScreen />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/billing/appointment-details"
            element={
              <>
                {IsAuthorized("Billing", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <BillingThirdScreen />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/billing/to-be-billed"
            element={
              <>
                {IsAuthorized("ToBeBilled", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <ToBeBlled />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/billing/billing-and-remittances"
            element={
              <>
                {IsAuthorized("BillingRemittances", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <BillingVsRemittance />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/billing/billing-and-remittances/billing-batch-report"
            element={
              <>
                {IsAuthorized("Billing", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <BillingBatchReport />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/billing/billing-and-remittances/remittances-Insurance"
            element={
              <>
                {IsAuthorized("Billing", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <RemittancesInsurance />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/provider-credentialing"
            element={
              <>
                {IsAuthorized("ProviderCredentialing", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <ProviderCredentialing />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/provider-credentialing/new-record"
            element={
              <>
                {IsAuthorized("ProviderCredentialing", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <NewRecord />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/aged-ar"
            element={
              <>
                {IsAuthorized("AgedAr", "page_level") ? (
                  <ProtectedRoute>
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <AgedAr />
                    </Suspense>
                  </ProtectedRoute>
                ) : (
                  <Suspense fallback={<DotsLoader height="vh-100" />}>
                    <Navigate
                      to="/"
                      replace={true}
                      state={{ isUnAuthorized: false }}
                    />
                  </Suspense>
                )}
              </>
            }
          />
          <Route
            exact
            path="/logout"
            element={
              <ProtectedRoute>
                <Suspense fallback={<DotsLoader height="vh-100" />}>
                  <Logout />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <UnProtectedRoute>
                <Suspense fallback={<DotsLoader height="vh-100" />}>
                  <LogIn />
                </Suspense>
              </UnProtectedRoute>
            }
          />
          <Route
            exact
            path="/forgot-password"
            element={
              <UnProtectedRoute>
                <Suspense fallback={<DotsLoader height="vh-100" />}>
                  <ForgotPassword />
                </Suspense>
              </UnProtectedRoute>
            }
          />
          <Route
            exact
            path="/accounts/password-reset-confirm/:uuid/:token"
            element={
              <UnProtectedRoute>
                <Suspense fallback={<DotsLoader height="vh-100" />}>
                  <ResetPassword />
                </Suspense>
              </UnProtectedRoute>
            }
          />
        </Routes>
      </Router>
      {/* </Suspense> */}
    </div>
  );
}

export default App;
