import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";
import HealthCareSolutionsHorizontal from "../../assets/images/healthcare-solutions-logo-horizontal.svg";

const Footer = () => {
  return (
    <footer
      className="fixed-bottom text-end px-3"
    >
      <Container fluid className="py-1">
        <Row>
          <Col md={12}>
            <p className="fs-14 d-inline me-3">Powered by</p>
            <img
              className="ms-auto"
              src={HealthCareSolutionsHorizontal}
              width={"120px"}
              height={"auto"}
              alt="Health care integrative solutions"
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default React.memo(Footer);
