// Staging Base Url
// const hostUrl = "https://athenadevapi.vigeodash.com/";

// Dev Base Url
// const hostUrl = "https://athenadevelopment-api.vigeodash.com/";

// prod Base Url
const hostUrl = "https://athenaapi.vigeodash.com/";

export const UpdateUserSessionTokenUrl =
  hostUrl + "accounts/auth/update_user_token/";

// athena logo url
export const AthenaLogoNavyGoldUrl =
  hostUrl + "static/logos/ASG_Primary_Logo_Navy_Gold.png";

export const AthenaLogoNavyGradientUrl =
  hostUrl + "static/logos/ASG_Secondary_Logo_Navy_Gradient.png";

export const refreshTokenUrl = hostUrl + "accounts/auth/api/token/refresh/";
export const dashboardSyncUrl = hostUrl + "data_sync/update_data";
export const getSyncedDateUrl = hostUrl + "accounts/get_last_sync/";

export const tenantIdUrl = hostUrl + "accounts/get_tenant_settings/";
export const tenantSettingsUrl = hostUrl + "accounts/update_tenant_settings/";

export const loginURL = hostUrl + "accounts/auth/login/";
export const LogoutURL = hostUrl + "accounts/auth/logout/";
export const registerUserUrl = hostUrl + "accounts/auth/registration/";
export const resetPasswordURL = hostUrl + "accounts/auth/password_reset";
export const PasswordResetURL =
  hostUrl + "accounts/auth/password/reset/confirm/";
export const getUsersURL = hostUrl + "accounts/get_all_users/";
export const UsersExportURL = hostUrl + "accounts/user/data_export/";
export const userSessionUrl = hostUrl + "accounts/user/get_session/";
export const deleteUserUrl = hostUrl + "accounts/delete_user/";
export const putUserUrl = hostUrl + "accounts/edit_user/";
export const updateUserDetailsURL = hostUrl + "accounts/edit_user_profile/";
export const updatePasswordURL = hostUrl + "accounts/auth/password/change/";
export const changePresentPasswordUrl =
  hostUrl + "accounts/update_pwd_for_a_user/";
export const getCompanyDetailsURL = hostUrl + "accounts/get_company_details/";
export const updateCompanyDetailsURL =
  hostUrl + "accounts/update_company_details/";

export const agedARUrl = hostUrl + "billing/billing_aged_report/";
export const agedARCostReportUrl =
  hostUrl + "billing_volume_tracker/billing_aged_cost_report/";

export const volumeTrackerURL =
  hostUrl + "billing_volume_tracker/crud_volume_tracker/";
export const lastSyncForVolumeTrackerUrl =
  hostUrl + "billing_volume_tracker/vt_last_sync/";

export const volumeTrackerSyncURL =
  hostUrl + "data_sync/manual_volume_tracker_sync/";
export const lastVolumeSyncUrl =
  hostUrl + "/billing_volume_tracker/vt_last_sync/";

export const mainExportURL = hostUrl + "static/exports/";

export const filterDropDownsURL =
  hostUrl + "billing_volume_tracker/filter_values/";

export const volumeTrackerDropdownsURL =
  hostUrl + "billing_volume_tracker/drop_downs/";

export const providerCredentialsURL =
  hostUrl + "provider_credentialing/crud_provider_credentialing/";

export const providerCredentialsDropDownURL =
  hostUrl + "provider_credentialing/drop_downs/";

export const providerCredentialsCRUDUrl =
  hostUrl + "primary_details/primary_tables/";

export const settingsCRUDURL = hostUrl + "primary_details/dynamic_tables/";

export const cipherRulesURL = hostUrl + "accounts/fetch_rules/";

export const releaseNotesListingUrl = hostUrl + "miscellaneous/release_note/";

export const billingHomeScreenUrl = hostUrl + "billing/billing_homescreen/";

export const toBeBilledUrl = hostUrl + "billing/to_be_billed_report/";
export const billingvsRemittanceUrl =
  hostUrl + "billing/billing_versus_remittance/";
export const billingBatchURL = hostUrl + "billing/billing_batch_report/";
export const remittancesURL =
  hostUrl + "billing/billing_remittance_by_insurance/";

export const billingInsuranceAddressDropdowns =
  hostUrl + "billing/billing_remittance_insurance_addr/";

export const billingHomeAccordionUrl = hostUrl + "billing/child_homescreen/";

export const billingDropdownsUrl = hostUrl + "billing/billing_dropdowns/";
export const billingDropdownsStateCityUrl =
  hostUrl + "billing/state_and_city_dropdown/";

export const billingAccordionUrl = hostUrl + "billing/s_c_i_actionable_claims/";
// export const billingAccordionUrl = hostUrl + "billing/actionable_claims_by_s_c_i/";

export const billingAppointmentDetailsUrl =
  hostUrl + "billing/appointment_details/";

export const billingAppointmentRangeUrl =
  hostUrl + "billing/appointments_screen/";
export const billingAppointmentSummaryUrl =
  hostUrl + "billing/appointment_level/";
export const billingAppointmentExportUrl =
  hostUrl + "billing/appointment_csv_export/";
export const billingMonthlySummaryUrl = hostUrl + "billing/monthly_summary/";
export const billingCardsUrl = hostUrl + "billing/line_items/";
export const billingAppointmentCommentsUrl = hostUrl + "billing/crud_comments/";
export const billingEOBUrl = hostUrl + "billing/view_s3_doc/";
export const billingAccordionFetchCityUrl = hostUrl + "billing/fetch_city/";
export const billingDownloadPdfUrl = hostUrl + "static/exports/";
export const billingFetchUsersUrl = hostUrl + "billing/fetch_billing_users/";

export const data_plyground_url =
  hostUrl + "miscellaneous/graph_n_tbl_data_playground/";
export const table_names_url =
  hostUrl + "miscellaneous/dropdown_dataplayground/";
export const home_scren_Admin_kpi_url = hostUrl + "billing/dashboard_admin/";
export const home_scren_Clinical_kpi_url =
  hostUrl + "billing/dashboard_clinical/";
export const syncHistoryUrl = hostUrl + "data_sync/get_sync_details/";


export const trainingManualListingUrl = hostUrl + "training_manuals/help_screen/";
