import React, { useEffect, useState } from 'react'
import {
    Editor, EditorState,
    RichUtils,
    convertToRaw,
    convertFromRaw,
} from "draft-js";

import Toolbar from "./Toolbar/Toolbar";
import "./DraftEditor.css";

const UpdateDraftEditor = ({ editorContent, setEditorContent }) => {
    // const contentState = convertFromRaw((editState))
    // const editorReadState = EditorState.createWithContent(contentState);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    useEffect(() => {
        setEditorState(editorContent)
    }, [editorContent])


    const editor = React.useRef(null);
    function focusEditor() {
        editor.current.focus();
    }

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            setEditorContent(newState)
            return true;
        }
        return false;
    };

    // FOR INLINE STYLES
    const styleMap = {
        CODE: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            fontSize: 16,
            padding: 2,
        },
        HIGHLIGHT: {
            backgroundColor: "#F7A5F7",
        },
        UPPERCASE: {
            textTransform: "uppercase",
        },
        LOWERCASE: {
            textTransform: "lowercase",
        },
        CODEBLOCK: {
            fontFamily: '"fira-code", "monospace"',
            fontSize: "inherit",
            background: "#ffeff0",
            fontStyle: "italic",
            lineHeight: 1.5,
            padding: "0.3rem 0.5rem",
            borderRadius: " 0.2rem",
        },
        SUPERSCRIPT: {
            verticalAlign: "super",
            fontSize: "80%",
        },
        SUBSCRIPT: {
            verticalAlign: "sub",
            fontSize: "80%",
        },
    };

    // FOR BLOCK LEVEL STYLES(Returns CSS Class From DraftEditor.css)
    const myBlockStyleFn = (contentBlock) => {
        const type = contentBlock.getType();
        switch (type) {
            case "blockQuote":
                return "superFancyBlockquote";
            case "leftAlign":
                return "leftAlign";
            case "rightAlign":
                return "rightAlign";
            case "centerAlign":
                return "centerAlign";
            case "justifyAlign":
                return "justifyAlign";
            default:
                break;
        }
    };


    // const contentState = convertFromRaw((editState))
    // const editorReadState = EditorState.createWithContent(contentState);
    // console.log(editorReadState);

    return (
        <div className="editor-wrapper"
            style={{ border: "1px solid black", minHeight: "6em", cursor: "text" }}
            onClick={focusEditor}>
            <Toolbar editorState={editorState} setEditorState={setEditorState} />
            <div className="editor-container" style={{ minHeight: "200px" }}>
                {/* <Editor editorState={editorReadState} readOnly={true} /> */}
                <Editor
                    ref={editor}
                    placeholder="Write Here"
                    handleKeyCommand={handleKeyCommand}
                    editorState={editorState}
                    customStyleMap={styleMap}
                    blockStyleFn={myBlockStyleFn}
                    onChange={(editorState) => {
                        setEditorState(editorState);
                        setEditorContent(editorState)
                        // const contentState = editorState.getCurrentContent();
                        // console.log(convertToRaw(contentState));
                        // console.log(JSON.stringify(convertToRaw(contentState)));
                    }}
                />
            </div>
        </div>
    )
}

export default React.memo(UpdateDraftEditor)