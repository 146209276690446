let authInfo = {
  reset: {
    no_access: [],
    limited_access: [],
  },
  HeaderMenu: {
    full_access: [
      "application_user",
      // "account_admin",
      "platform_admin",
      "platform_user",
      "application_admin",
      "department_manager_clinical",
      // "department_manager_sales",
      "department_manager_billing",
      "department_user_billing",
      "department_user_sales",
      "department_user_clinical",
      "multi_department_manager_billing",
      "multi_department_manager_sales",
      "multi_department_manager_clinical",
    ],
    no_access: ["department_manager_sales", "account_admin"],
    limited_access: [],
  },

  HomeScreen: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [],
      limited_access: [],
    },
    homescreen_admin: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        //  "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    homescreen_clinical: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        //  "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        // "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "account_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
        "department_user_billing",
        "department_user_sales",
      ],
      limited_access: [],
    },
  },

  Billing: {
    page_level: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        //  "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "multi_department_manager_sales",
        "department_manager_clinical",
        "multi_department_manager_clinical",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: ["department_user_billing"],
    },
    // "masked_kpi_values": {
    //     "full_access": [
    //         "platform_admin",
    //         "application_admin",
    //         "department_manager_billing",
    //         "multi_department_manager_billing",
    //     ],
    //     "no_access": [
    //         "department_user_billing",
    //         "platform_user",
    //         "application_user",
    //         "account_admin",
    //         "department_manager_sales",
    //         "multi_department_manager_sales",
    //         "department_manager_clinical",
    //         "multi_department_manager_clinical",
    //         "department_user_sales",
    //         "department_user_clinical",
    //     ],
    //     "limited_access": []
    // },
  },

  // billing screen authorization

  // aged-AR screen authorization

  AgedAr: {
    page_level: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
  },

  ToBeBilled: {
    page_level: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        //  "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "platform_user",

        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
  },

  BillingRemittances: {
    page_level: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        //  "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
  },

  ProviderCredentialing: {
    page_level: {
      full_access: [
        "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
      ],
      limited_access: [],
    },
  },

  VolumeTracker: {
    page_level: {
      full_access: [
        "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "account_admin",
        "multi_department_manager_sales",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "application_user",
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "platform_user",
        "account_admin",
        "department_manager_sales",
        "multi_department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    view_comments: {
      full_access: [
        "application_user",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
  },

  Users: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        // "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        // "multi_department_manager_billing",
        // "multi_department_manager_sales",
        // "multi_department_manager_clinical",
      ],
      no_access: [
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    export: {
      full_access: ["account_admin", "platform_admin", "application_admin"],
      no_access: [
        "platform_user",
        "application_user",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    update_user_functionality: {
      full_access: [
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
      ],
      no_access: [
        "application_user",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
  },
  SyncHistory: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [],
      limited_access: [],
    },
  },
  ReleaseHistory: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [],
      limited_access: [],
    },
    add_new_release_history: {
      full_access: ["platform_admin", "platform_user"],
      no_access: [
        "application_user",
        "account_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    update_release_history: {
      full_access: ["platform_admin", "platform_user"],
      no_access: [
        "application_user",
        "account_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
  },

  DataPlayGround: {
    page_level: {
      full_access: [
        "application_user",
        "platform_admin",
        "platform_user",
        "application_admin",
      ],
      no_access: [
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    export: {
      full_access: ["application_user", "platform_admin", "application_admin"],
      no_access: [
        "platform_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
  },

  Settings: {
    page_level: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "multi_department_manager_sales",
        "department_user_sales",
      ],
      limited_access: [],
    },
    other_settings: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_billing",
        "department_manager_billing",
        "multi_department_manager_sales",
        "department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    provider_credentials: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    provider_credentials_crud: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
        "department_user_billing",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    provider_credentials_export: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
        "department_user_billing",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    comment_category: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    comment_category_crud: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    comment_category_export: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    volume_tracker_comments: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    volume_tracker_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_billing",
      ],
      limited_access: [],
    },
    volume_tracker_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_billing",
      ],
      limited_access: [],
    },
    volume_tracker_stages: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    volume_tracker_stages_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_billing",
      ],
      limited_access: [],
    },
    volume_tracker_stages_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_billing",
      ],
      limited_access: [],
    },
    billing_status_workflow: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        // "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    billing_status_workflow_crud: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        // "multi_department_manager_clinical",
      ],
      no_access: [
        "department_user_billing",
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    billing_status_workflow_export: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        // "multi_department_manager_clinical",
      ],
      no_access: [
        "department_user_billing",
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    payor_details: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "multi_department_manager_billing",
        "department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    payor_details_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "multi_department_manager_billing",
        "department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    payor_details_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "multi_department_manager_billing",
        "department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    state_fips_code: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        // "multi_department_manager_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    state_fips_code_crud: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        // "multi_department_manager_clinical",
      ],
      no_access: [
        "department_user_billing",
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    state_fips_code_export: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        // "department_manager_clinical",
        // "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        // "multi_department_manager_sales",
        // "multi_department_manager_clinical",
      ],
      no_access: [
        "department_user_billing",
        "application_user",
        "account_admin",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    tooltip_info: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    tooltip_info_crud: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    tooltip_info_export: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
      ],
      limited_access: [],
    },
  },

  settings_billing_status_workflow: {
    no_access: [
      "application_user",
      "account_admin",
      "multi_department_manager_sales",
      "multi_department_manager_clinical",
      "department_user_sales",
      "department_user_clinical",
    ],
    limited_access: ["department_user_billing"],
  },
  settings_billing_status_workflow_export: {
    no_access: [],
    limited_access: ["department_user_billing"],
  },
};

// export const IsAuthorized = (screen, view) => {
//   // current loggedIn user
//   let user = JSON.parse(localStorage.getItem("hnts_user"));

//   let unauthorizedUser = user?.consolidated_role_and_dep
//     ? user?.consolidated_role_and_dep.filter((item, index) =>
//         authInfo[screen]?.no_access.includes(item)
//       )
//     : [];

//   // let loggedInRoles = user?.consolidated_role_and_dep ? user?.consolidated_role_and_dep : [];
//   // let typeOfAccess = "";
//   // for (let singleRole in loggedInRoles) {
//   //     if (authInfo[screen]?.full_access?.includes(loggedInRoles[singleRole])) {
//   //         typeOfAccess = "full_access";
//   //     } else if (authInfo[screen]?.no_access.includes(loggedInRoles[singleRole])) {
//   //         typeOfAccess = "no_access";
//   //     }
//   // }

//   // let unauthorizedUserValue = unauthorizedUser.length > 0 ? true : false
//   // console.log(unauthorizedUserValue);

//   // checking users with view access
//   // let userWithLimitedViewAccess = authInfo[screen].limited_access.includes(user?.role)

//   let userWithLimitedViewAccess = user?.consolidated_role_and_dep
//     ? user?.consolidated_role_and_dep.filter((item, index) =>
//         authInfo[screen]?.limited_access.includes(item)
//       )
//     : [];

//   let isAuthorized = true;

//   if (!view) {
//     if (unauthorizedUser.length) {
//       isAuthorized = false;
//     } else {
//       isAuthorized = true;
//     }
//   } else if (screen && view) {
//     if (userWithLimitedViewAccess.length) {
//       isAuthorized = false;
//     } else {
//       isAuthorized = true;
//     }
//   } else {
//     isAuthorized = true;
//   }
//   return isAuthorized;
// };

let user = JSON.parse(localStorage.getItem("hnts_user"));
let loggedInRoles = user?.consolidated_role_and_dep
  ? user?.consolidated_role_and_dep
  : [];
let typeOfAccess = "";
export const IsAuthorized = (screenName, sectionName) => {
  // current loggedIn user
  // let unauthorizedUser = user?.consolidated_role_and_dep ? user?.consolidated_role_and_dep.filter((item, index) => (
  //     authInfo[screen]?.no_access.includes(item)
  // )) : [];
  if (sectionName) {
    for (let singleRole in loggedInRoles) {
      if (
        authInfo[screenName][sectionName]?.full_access?.includes(
          loggedInRoles[singleRole]
        )
      ) {
        return (typeOfAccess = "full_access");
      } else if (
        authInfo[screenName][sectionName]?.no_access.includes(
          loggedInRoles[singleRole]
        )
      ) {
        typeOfAccess = "no_access";
      } else if (
        authInfo[screenName][sectionName]?.limited_access?.includes(
          loggedInRoles[singleRole]
        )
      ) {
        typeOfAccess = "limited_access";
      }

      // console.log(loggedInRoles[singleRole], screenName, sectionName, typeOfAccess);
    }
  } else {
    for (let singleRole in loggedInRoles) {
      if (
        authInfo[screenName]?.full_access?.includes(loggedInRoles[singleRole])
      ) {
        return (typeOfAccess = "full_access");
      } else if (
        authInfo[screenName]?.no_access.includes(loggedInRoles[singleRole])
      ) {
        typeOfAccess = "no_access";
      }
    }
  }
  // console.log("AccessType", typeOfAccess)
  let isAccessible = true;
  if (typeOfAccess === "full_access") {
    isAccessible = true;
  } else {
    isAccessible = false;
  }
  // console.log("isAccessible", isAccessible)
  return isAccessible;
};

export const limitedAccessFunction = (screenName, sectionName) => {
  for (let singleRole in loggedInRoles) {
    if (
      authInfo[screenName][sectionName]["limited_access"]?.includes(
        loggedInRoles[singleRole]
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
};
