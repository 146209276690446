import { useLocation, Navigate } from "react-router-dom";
import Layout from "../common/utilsFunctions/Layout";

export function ProtectedRoute({ children }) {
  const location = useLocation();

  /*To change the selected tab for primarydetails settings.
  If you want use this code in another project remove this if block */
  if(location.pathname !== "/settings"){
    sessionStorage.setItem("primaryDetailsSelectedTab","providerCredentialing");
  }
  
  let user = localStorage.getItem("user")
  if (user) {
    return <Layout>{children}</Layout>;
  } else {
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }
}
export function UnProtectedRoute({ children }) {
  if (localStorage.getItem("user")) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
}
