import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faAlignCenter,
//     faAlignLeft,
//     faAlignRight,
//     faBold,
//     faChevronDown,
//     faChevronUp,
//     faCode,
//     faHighlighter,
//     faItalic,
//     faListOl,
//     faListUl,
//     faQuoteRight,
//     faStrikethrough,
//     faSubscript,
//     faSuperscript,
//     faTextWidth,
//     faUnderline,
// } from "@fortawesome/free-solid-svg-icons";
import { RichUtils } from "draft-js";

import Bold_icon from "./toolbarImages/bold.svg"
import Italic_icon from "./toolbarImages/italic.svg"
import Underline_icon from "./toolbarImages/underline.svg"
import highlighter_icon from "./toolbarImages/highlighter-pen-marker.svg"
import strikethrough_icon from "./toolbarImages/strikethrough.svg"
import superscript_icon from "./toolbarImages/superscript.svg"
import subscript_icon from "./toolbarImages/subscript.svg"
import monospace_icon from "./toolbarImages/monospace.svg"
import blockquote_icon from "./toolbarImages/blockquote.svg"
import unorderedlist_icon from "./toolbarImages/unordered-list.svg"
import orderedlist_icon from "./toolbarImages/list-ordered.svg"
import code_icon from "./toolbarImages/code.svg"
import leftalign_icon from "./toolbarImages/left-align.svg"
import centeralign_icon from "./toolbarImages/center-align.svg"
import rightalign_icon from "./toolbarImages/right-align.svg"
import lowertCase_icon from "./toolbarImages/lowerCase.svg"
import upperCase_icon from "./toolbarImages/upperCase.svg"

import "../DraftEditor.css"

const Toolbar = ({ editorState, setEditorState }) => {
    const tools = [
        {
            label: "bold",
            style: "BOLD",
            icon: <img className="icons" src={Bold_icon} />,
            method: "inline",
        },
        {
            label: "italic",
            style: "ITALIC",
            icon: <img className="icons" src={Italic_icon} />,
            method: "inline",
        },
        {
            label: "underline",
            style: "UNDERLINE",
            icon: <img className="icons" src={Underline_icon} />, method: "inline",
        },
        {
            label: "highlight",
            style: "HIGHLIGHT",
            icon: <img className="icons" src={highlighter_icon} />,
            method: "inline",
        },
        {
            label: "strike-through",
            style: "STRIKETHROUGH",
            icon: <img className="icons" src={strikethrough_icon} />,
            method: "inline",
        },
        {
            label: "Superscript",
            style: "SUPERSCRIPT",
            icon: <img className="icons" src={superscript_icon} />,
            method: "inline",
        },
        {
            label: "Subscript",
            style: "SUBSCRIPT",
            icon: <img className="icons" src={subscript_icon} />,
            method: "inline",
        },
        {
            label: "Monospace",
            style: "CODE",
            icon: <img className="icons" src={monospace_icon} />,
            method: "inline",
        },
        {
            label: "Blockquote",
            style: "blockQuote",
            icon: <img className="icons" src={blockquote_icon} />,
            method: "block",
        },
        {
            label: "Unordered-List",
            style: "unordered-list-item",
            method: "block",
            icon: <img className="icons" src={unorderedlist_icon} />,
        },
        {
            label: "Ordered-List",
            style: "ordered-list-item",
            method: "block",
            icon: <img className="icons" src={orderedlist_icon} />,
        },
        {
            label: "Code Block",
            style: "CODEBLOCK",
            icon: <img className="icons" src={code_icon} />,
            method: "inline",
        },
        {
            label: "Uppercase",
            style: "UPPERCASE",
            icon: <img className="icons" src={upperCase_icon} />,
            method: "inline",
        },
        {
            label: "lowercase",
            style: "LOWERCASE",
            icon: <img className="icons" src={lowertCase_icon} />,
            method: "inline",
        },
        {
            label: "Left",
            style: "leftAlign",
            icon: <img className="icons" src={leftalign_icon} />,
            method: "block",
        },
        {
            label: "Center",
            style: "centerAlign",
            icon: <img className="icons" src={centeralign_icon} />,
            method: "block",
        },
        {
            label: "Right",
            style: "rightAlign",
            icon: <img className="icons" src={rightalign_icon} />,
            method: "block",
        },
        { label: "H1", style: "header-one", method: "block" },
        { label: "H2", style: "header-two", method: "block" },
        { label: "H3", style: "header-three", method: "block" },
        { label: "H4", style: "header-four", method: "block" },
        { label: "H5", style: "header-five", method: "block" },
        { label: "H6", style: "header-six", method: "block" },
    ];

    const applyStyle = (e, style, method) => {
        e.preventDefault();
        method === "block"
            ? setEditorState(RichUtils.toggleBlockType(editorState, style))
            : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const isActive = (style, method) => {
        if (method === "block") {
            const selection = editorState.getSelection();
            const blockType = editorState
                .getCurrentContent()
                .getBlockForKey(selection.getStartKey())
                .getType();
            return blockType === style;
        } else {
            const currentStyle = editorState.getCurrentInlineStyle();
            return currentStyle.has(style);
        }
    };

    return (
        <div className="toolbar-grid mb-2">
            {tools.map((item, idx) => (
                <button
                    style={{
                        color: isActive(item.style, item.method)
                            ? "rgba(0, 0, 0, 1)"
                            : "rgba(0, 0, 0, 0.3)",
                    }}
                    key={`${item.label}-${idx}`}
                    title={item.label}
                    onClick={(e) => applyStyle(e, item.style, item.method)}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {item.icon || item.label}
                    {/* {item.label} */}
                </button>
            ))}
        </div>
    );
};

export default Toolbar;
