import React, { useState } from "react";
import { Container, Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";

import { IsAuthorized } from "../utilsFunctions/IsAuthorized";

import { ReactComponent as VigeoDash } from "../../assets/images/vigeodash-logo.svg";
import { ReactComponent as Athena } from "../../assets/images/ASG_Primary_Logo_Navy_Gold.svg";
import userAvatar from "../../assets/images/user-Avtar.svg";
import { Link } from "react-router-dom";
import "./index.css";

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);

  // states to toggle admin dropdown
  const [show, setShow] = useState(false);
  const showDropdownMenu = (e) => {
    setShow(!show);
  };
  const hideDropdownMenu = (e) => {
    setShow(false);
  };

  // states to toggle experimental
  const [showExperimental, setShowExperimental] = useState(false);
  const showExperimentalDropdownMenu = (e) => {
    setShowExperimental(!show);
  };
  const hideExperimentalDropdownMenu = (e) => {
    setShowExperimental(false);
  };

  return (
    <header className="sticky-top header">
      <Navbar expand="lg" className="bg-body-tertiary px-3 py-0">
        <Container fluid>
          <Navbar.Brand bsPrefix="py-0 navbar-brand" href="/">
            <VigeoDash />
            {/* <img
              src={VigeoDash}
              width={"100px"}
              height={"auto"}
              alt="vigeodash"
            /> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-5 me-auto align-items-center">
              {IsAuthorized("HeaderMenu") && (
                <>
                  <NavDropdown
                    className="me-4 py-2 fs-14"
                    title="Admin"
                    id="basic-nav-dropdown"
                    show={show}
                    onMouseEnter={showDropdownMenu}
                    onMouseLeave={hideDropdownMenu}
                  >
                    {IsAuthorized("Billing", "page_level") && (
                      <NavDropdown.Item
                        className="fs-12"
                        href="/billing-dashboard"
                      >
                        Billing
                      </NavDropdown.Item>
                    )}

                    {IsAuthorized("VolumeTracker", "page_level") && (
                      <NavDropdown.Item
                        className="fs-12"
                        href="/volume-tracker"
                      >
                        Volume Tracker
                      </NavDropdown.Item>
                    )}
                    {IsAuthorized("ProviderCredentialing", "page_level") && (
                      <NavDropdown.Item
                        className="fs-12"
                        href="/provider-credentialing"
                      >
                        Provider Credentialing
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>

                  <NavDropdown
                    className="ms-4 py-2 fs-14"
                    title="Experimental"
                    id="basic-nav-dropdown"
                    show={showExperimental}
                    onMouseEnter={showExperimentalDropdownMenu}
                    onMouseLeave={hideExperimentalDropdownMenu}
                  >
                    {IsAuthorized("DataPlayGround", "page_level") && (
                      <NavDropdown.Item
                        className="fs-12"
                        href="/data-playground"
                      >
                        Data Playground
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                </>
              )}
            </Nav>

            <Nav className="ms-auto align-items-center">
              <Nav.Link className="me-5 py-0 pe-none athena-logo" href="#">
                <Athena style={{ width: "178px" }} />
              </Nav.Link>

              <Dropdown
                show={showDropdown}
                align="end"
                id="dropdown-menu-align-end"
                onMouseLeave={() => setShowDropdown(false)}
                onMouseOver={() => setShowDropdown(true)}
                onToggle={(isOpen, event, metadata) => {
                  // Always return false to prevent the dropdown from closing
                  setShowDropdown(isOpen);
                  // return false;
                }}
              >
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  className="py-3"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation(); // Stop the event propagation to prevent closing
                  }}
                >
                  <img
                    src={userAvatar}
                    width={"24px"}
                    height={"24px"}
                    alt="vigeodash"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                >
                  {IsAuthorized("Users", "page_level") && (
                    <Dropdown.Item as={Link} to="/users" className="fs-12">
                      Users
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("Settings", "page_level") && (
                    <Dropdown.Item as={Link} to="/settings" className="fs-12">
                      Settings
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("SyncHistory", "page_level") && (
                    <Dropdown.Item
                      as={Link}
                      to="/sync-history"
                      className="fs-12"
                    >
                      Sync History
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("ReleaseHistory", "page_level") && (
                    <Dropdown.Item
                      as={Link}
                      to="/release-history"
                      className="fs-12"
                    >
                      Release History
                    </Dropdown.Item>
                  )}

                  {/* {IsAuthorized("ReleaseHistory", "page_level") && */}
                  <Dropdown.Item
                    onClick={() => {
                      console.log("ReleaseHistory");
                    }}
                    as={Link}
                    to="/help"
                    className="fs-12"
                  >
                    Help
                  </Dropdown.Item>
                  {/* } */}

                  <Dropdown.Item
                    as={Link}
                    target="_blank"
                    className="fs-12"
                    to="https://forms.clickup.com/9006116846/f/8ccwvze-3313/LYX0WIKJWDP3XEJLWW"
                  >
                    Report An Issue
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/logout"
                    state={{ list: "manuallogout" }}
                    className="fs-12"
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default React.memo(Header);
