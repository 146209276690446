import React, { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { sendLogToCloudWatch } from "../../common/utilsFunctions/sendLogToCloudWatch";
import { LogoutURL } from "../../utils/urls";
import getNewAccessToken from "../../utils/GlobalRefreshToken/RefreshToken";
import DotsLoader from "../../common/DotsLoader";

const Logout = () => {
  const AxiosInstance = axios.create();
  const location = useLocation();
  let sessionId = JSON.parse(localStorage.getItem("sessionId"));
  let key = JSON.parse(localStorage.getItem("hnts_access_token"));

  const defaultKey =
    location.state && location.state.list && location.state.list;

  let navigate = useNavigate();

  const logoutSuccessResponse = (response) => {
    // if (defaultKey === "manuallogout") {
    //   localStorage.clear();
    //   navigate("/login", { state: "manuallogout" });
    //   sessionStorage.setItem("sessionlogout", "manuallogout");
    //   sendLogToCloudWatch(`Logout : manually logout`);
    // } else {
    //   localStorage.clear();
    //   sessionStorage.setItem("sessionlogout", "autologout");
    //   navigate("/login", { state: "autologout" });
    //   sendLogToCloudWatch(`Logout : auto logout`);
    // }
  };

  const loginFailureResponse = (error) => {
    console.log(error);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let headers = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let payload = {
      access_token: key,
      session_id: sessionId,
    };

    AxiosInstance.post(LogoutURL, payload, { withCredentials: true }, headers)
      .then((response) => {
        logoutSuccessResponse(response);
        sendLogToCloudWatch("logout : Successfully logged out");
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          let requiredAccessToken = await getNewAccessToken();
          let headers = {
            headers: {
              Authorization: `Bearer ${requiredAccessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };

          requiredAccessToken !== undefined
            ? AxiosInstance.post(
                LogoutURL,
                payload,
                { withCredentials: true },
                headers
              )
                .then((response) => {
                  logoutSuccessResponse(response);
                  sendLogToCloudWatch("logout : Successfully logged out");
                })
                .catch((error) => {
                  if (error?.response?.status === 401) {
                    console.log("Access token not updated");
                  }
                  loginFailureResponse(error);
                })
            : navigate("/logout");
        } else {
          loginFailureResponse(error);
        }
        sendLogToCloudWatch(
          `logout : logout failed with status code ${error?.response?.status}`
        );
      })
      .finally(() => {
        if (defaultKey === "manuallogout") {
          localStorage.clear();
          navigate("/login", { state: "manuallogout" });
          sessionStorage.setItem("sessionlogout", "manuallogout");
          sendLogToCloudWatch(`Logout : manually logout`);
        } else {
          localStorage.clear();
          sessionStorage.setItem("sessionlogout", "autologout");
          navigate("/login", { state: "autologout" });
          sendLogToCloudWatch(`Logout : auto logout`);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DotsLoader height="vh-100" />
    </div>
  );
};

export default Logout;
