import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

// Function to retrieve secret values from AWS Secrets Manager
const getSecret = (secretName, callback) => {
  const secretsManager = new AWS.SecretsManager();

  secretsManager.getSecretValue({ SecretId: secretName }, function (err, data) {
    if (err) {
      callback(err, null);
    } else {
      let secret;
      if ("SecretString" in data) {
        secret = JSON.parse(data.SecretString);
      } else {
        const buff = Buffer.from(data.SecretBinary, "base64");
        secret = JSON.parse(buff.toString("utf-8"));
      }
      callback(null, secret);
    }
  });
};

export const sendLogToCloudWatch = (logMessage) => {
  const cloudwatchlogs = new AWS.CloudWatchLogs();

  // Use specific secret name based on application
  const secretName = "logging-both-secrets";

  getSecret(secretName, function (err, secret) {
    if (err) {
      // console.error("Error retrieving secret:", err);
    } else {
      // update logGroup and logStream based on each application
      const logGroup = secret.athena_prod_details.cloudwatch_log_group_name;
      const logStream = secret.athena_prod_details.cloudwatch_log_stream_name;

      // console.log(logGroup, logStream, "log-names");

      // Check if log group exists
      cloudwatchlogs.describeLogGroups(
        { logGroupNamePrefix: logGroup },
        function (err, data) {
          if (err) {
            console.error("Error checking log group:", err);
          } else {
            if (data.logGroups.length === 0) {
              // Log group doesn't exist, create it
              cloudwatchlogs.createLogGroup(
                { logGroupName: logGroup },
                function (err, data) {
                  if (err) {
                    // console.error("Error creating log group:", err);
                  } else {
                    // console.log("Log group created:", data);
                    // After creating log group, create log stream
                    createLogStream(logStream);
                  }
                }
              );
            } else {
              // Log group exists, proceed to check log stream
              createLogStream(logStream);
            }
          }
        }
      );

      function createLogStream(logStream) {
        // Check if log stream exists
        cloudwatchlogs.describeLogStreams(
          { logGroupName: logGroup, logStreamNamePrefix: logStream },
          function (err, data) {
            if (err) {
              console.error("Error checking log stream:", err);
            } else {
              if (data.logStreams.length === 0) {
                // Log stream doesn't exist, create it
                cloudwatchlogs.createLogStream(
                  { logGroupName: logGroup, logStreamName: logStream },
                  function (err, data) {
                    if (err) {
                      // console.error("Error creating log stream:", err);
                    } else {
                      // console.log("Log stream created:", data);
                      // After creating log stream, send log
                      sendLog(logStream);
                    }
                  }
                );
              } else {
                // Log stream exists, proceed to send log
                sendLog(logStream);
              }
            }
          }
        );
      }

      function sendLog(logStream) {
        const params = {
          logGroupName: logGroup,
          logStreamName: logStream,
          logEvents: [
            {
              timestamp: new Date().getTime(),
              message: logMessage,
            },
          ],
        };

        cloudwatchlogs.putLogEvents(params, function (err, data) {
          if (err) {
            // console.error("Error sending log to CloudWatch Logs:", err);
          } else {
            // console.log("Log sent to CloudWatch Logs:", data);
          }
        });
      }
    }
  });
};
