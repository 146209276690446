import React from 'react'
import "./dots.css";

const DotsLoader = ({ height = "form-field-height" }) => {
    return (
        <div className={`${height ? height : "h-100"} loading-dots`}>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
        </div>
    )
}

export default DotsLoader