import axios from "axios";
import { UpdateUserSessionTokenUrl, refreshTokenUrl } from "../urls";

const RefreshToken = async (refreshToken) => {
  let fetchResponse = await fetch(refreshTokenUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error.json();
    });
  console.log("First in Refresh Component");
  return fetchResponse;
};

const getNewAccessToken = async () => {
  let oldAccessToken = JSON.parse(localStorage.getItem("hnts_access_token"));
  let refreshToken = JSON.parse(localStorage.getItem("hnts_refresh_token"));
  let refreshTokenResponse = [];
  refreshTokenResponse = await RefreshToken(refreshToken);

  if (refreshTokenResponse?.access && refreshTokenResponse.refresh) {
    localStorage.setItem(
      "hnts_access_token",
      JSON.stringify(
        refreshTokenResponse?.access ? refreshTokenResponse.access : "No Value"
      )
    );
    localStorage.setItem(
      "hnts_refresh_token",
      JSON.stringify(
        refreshTokenResponse?.refresh
          ? refreshTokenResponse.refresh
          : "No Value"
      )
    );

    let payload = {
      old_access: oldAccessToken,
      new_access: refreshTokenResponse?.access,
    };

    let headers = {
      headers: {
        Authorization: `Bearer ${refreshTokenResponse.access}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios
      .post(
        UpdateUserSessionTokenUrl,
        payload,
        { withCredentials: true },
        headers
      )
      .then((response) => {
        console.log(response);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  let newAccessToken = refreshTokenResponse?.access;
  return newAccessToken;
};

export default getNewAccessToken;
